import http from '@/shared/http'
import apiResultWrapper from '@/shared/apiResultWrapper'

// fetch information about blotter accounts - in BE is organize structure of users
const fetchAccounts = async () => apiResultWrapper.apiResult(await http.get('/api/v1/reports/sterling/accounts'))
// fetch available report in sterling reports
const fetchAvailableReports = async () => apiResultWrapper.apiResult(await http.get('/api/v1/reports/sterling/available-reports'))

export default {
  fetchAccounts,
  fetchAvailableReports
}
