import staerlingReportsService from '@/service/SterlingReportsService'
import axios from 'axios'

export default {
  name: 'SterlingReports',
  components: {
  },
  data: () => ({
    selectedAccessId: '',
    selectedReport: '',
    selectedDateRange: 'NO_SELECTED', // default value
    availableReports: [
    ],
    dateRange: [{
      label: 'Last Business Day',
      value: 'LAST_BUSINESS_DAY'
    }, {
      label: 'This Week',
      value: 'THIS_WEEK'
    }, {
      label: 'Last Week',
      value: 'LAST_WEEK'
    }, {
      label: 'This Month',
      value: 'THIS_MONTH'
    }, {
      label: 'Last Month',
      value: 'LAST_MONTH'
    }, {
      label: 'Custom',
      value: 'CUSTOM'
    }],
    accounts: [],
    loading: false,
    search: {
      from: '',
      to: '',
      fromModel: false,
      toModel: false
    },
    report: ''
  }),
  watch: {
    async selectedAccessId (val) {
      this.selectedUserId = this.accounts.filter(i => i.name === val)[0].userId
    },
    selectedDateRange: function (val) {
      this.selectedDateRange = val
      const today = new Date()
      const from = new Date()
      const to = new Date()
      switch (val) {
        case 'LAST_BUSINESS_DAY':
          // 0 - S => -2
          // 1 - M => -3
          // 2 - T => -1
          // 3 - W => -1
          // 4 - T => -1
          // 5 - F => -1
          // 6 - S => -1
          from.setDate(today.getDate() - (today.getDay() === 0 ? 2 : (today.getDay() === 1 ? 3 : 1)))
          to.setDate(today.getDate() - (today.getDay() === 0 ? 2 : (today.getDay() === 1 ? 3 : 1)))
          break
        case 'THIS_WEEK':
          from.setDate(today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1))
          break
        case 'LAST_WEEK':
          from.setDate(today.getDate() - (today.getDay() === 0 ? 13 : today.getDay() + 6))
          to.setDate(today.getDate() - (today.getDay() === 0 ? 7 : today.getDay()))
          // to.setDate(today.getDate() - (today.getDay() === 0 ? 7 : 8 - today.getDay()))
          break
        case 'THIS_MONTH':
          from.setMonth(today.getMonth(), 1)
          // to.setMonth(today.getMonth() + 1, 0)
          break
        case 'LAST_MONTH':
          from.setMonth(today.getMonth() - 1, 1)
          to.setMonth(today.getMonth(), 0)
          break
        case 'CUSTOM':
        default:
      }

      this.search.from = this.formatDate(from)
      this.search.to = this.formatDate(to)
    }
  },
  computed: {
    maxDay: () => {
      return new Date().toISOString().substr(0, 10)
    }
  },
  beforeMount () {
    this.fetchAccounts()
    this.fetchAvailableReports()
    this.selectedDateRange = 'LAST_BUSINESS_DAY'
  },
  methods: {
    async fetchAccounts () {
      this.loading = true
      const response = await staerlingReportsService.fetchAccounts()
      if (response.status === 200) {
        this.accounts = response.data
        this.selectedAccessId = this.accounts[0].name
      }
      this.loading = false
    },
    async fetchAvailableReports () {
      this.loading = true
      const response = await staerlingReportsService.fetchAvailableReports()
      if (response.status === 200) {
        this.availableReports = response.data
        this.selectedReport = this.availableReports[0].value
      }
      this.loading = false
    },
    async fetchReport () {
      this.loading = true

      // request
      let query = `report-type=${this.selectedReport}`
      if (this.search.from) {
        query += `&from=${new Date(this.search.from).getTime()}`
      }
      if (this.search.to) {
        query += `&to=${new Date(this.search.to).getTime()}`
      }
      console.log(this.selectedAccessId)
      console.log(this.accounts)
      console.log(this.accounts.filter((item) => item.name === this.selectedAccessId))
      if (this.selectedAccessId) {
        const accessId = this.accounts.filter((item) => item.name === this.selectedAccessId)[0].accessId
        query += `&accessId=${accessId}`
        const accountId = this.accounts.filter((item) => item.name === this.selectedAccessId)[0].accountId
        query += `&accountId=${accountId}`
      }

      axios.get(`/api/v1/reports/sterling/fetch-report?${query}`)
        .then(r => {
          this.report = r.data
          this.loading = false
        })
        .catch(e => {
          this.report = ''
          this.loading = false
        })
    },
    async exportReport () {
      const selectReportLabels = this.availableReports.filter(item => item.value === this.selectedReport)
      const reportName = selectReportLabels.length === 0 ? 'Unknown' : selectReportLabels[0].label
      const filename = `${reportName} ${this.search.from}-${this.search.to}.xls`
      const uri = `data:application/vnd.ms-excel;filename=${filename};base64,`
      const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets>'
      const templateend = '</x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head>'
      const body = '<body>'
      const tablevar = '<table>{table'
      const tablevarend = '}</table>'
      const bodyend = '</body></html>'
      const worksheet = '<x:ExcelWorksheet><x:Name>'
      const worksheetend = '</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>'
      const worksheetvar = '{worksheet'
      const worksheetvarend = '}'
      const base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      const format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p] }) }
      let wstemplate = ''
      let tabletemplate = ''

      const tables = document.getElementsByClassName('report')

      for (var i = 0; i < tables.length; ++i) {
        wstemplate += worksheet + worksheetvar + i + worksheetvarend + worksheetend
        tabletemplate += tablevar + i + tablevarend
      }

      const allTemplate = template + wstemplate + templateend
      const allWorksheet = body + tabletemplate + bodyend
      const allOfIt = allTemplate + allWorksheet

      const ctx = {}
      for (let j = 0; j < tables.length; ++j) {
        ctx['worksheet' + j] = j
      }

      for (let k = 0; k < tables.length; ++k) {
        ctx['table' + k] = tables[k].innerHTML
      }

      // window.location.href = uri + base64(format(allOfIt, ctx))

      let link = document.createElement('a')
      link.href = uri + base64(format(allOfIt, ctx))
      link.download = filename
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      link = null
    },
    formatDate (date) {
      const year = date.getFullYear()
      let month = date.getMonth()
      const day = date.getDate()

      month = month + 1
      return [
        year < 9 ? '0' + year : year,
        month < 10 ? '0' + month : month,
        day < 10 ? '0' + day : day].join('-')
    }
  }
}
